<template>
  <div>
    <InputField v-model="$v.value.$model" type="text" :field="fakeField" />
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import InputField from "@/components/Tools/FormHelper/Components/Input";

export default {
  name: "ArrayInput",
  components: { InputField },
  mixins: [base],
  props: {},
  data() {
    return {
      returnType: "text",
      showVariables: false,
      fakeField: {
        enableTypecast: true,
        enableVariables: true,
        placeholder: "value",
        type: "text"
      }
    };
  },
  computed: {
    value: {
      get: function () {
        return this.defaultValue;
      },
      set: function (value) {
        this.onChange(value);
        this.onInput(value);
      }
    }
  },
  mounted() {},
  methods: {}
};
</script>
